import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import backBtn from "../../assets/backBtn.svg";
import typographyStyles from "../../theme/Typography";
import LogoutModal from "../../Modal/LogoutModal";
import { useNavigate } from "react-router-dom";

const dashboardLayoutStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
  },

  headerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "1%",
  },

  sectionTitle: {
    ...typographyStyles.h4,
    marginLeft: "10px",
  },

  contentSection: {
    display: "flex",
    flex: 1, // Ensures it takes the remaining space
    height: "100%", // Full height to handle scrolling
    overflow: "hidden", // Prevent scrolling outside content
  },

  sectionNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  siderbarStyle: {
    width: "20%",
    // minHeight: "100vh",
    boxShadow: "0 10px 10px -10px #D9D9D9, 10px 0 10px -10px #D9D9D9",
  },

  contentStyle: {
    width: "80%",
    padding: "16px 2%",
    margin: "12px",
    boxShadow: "0 0 10px 0 #D9D9D9",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden", // Prevent overflow for the entire content
  },

  sectionNameStyle: {
    ...typographyStyles.h4,
    textAlign: "end",
  },

  scrollableChildren: {
    flex: 1, // Fills the remaining space
    overflowY: "auto", // Vertical scrolling
    overflowX: "hidden", // Prevent horizontal scrolling
    scrollbarWidth: "none", // Hides scrollbar in Firefox
    "-ms-overflow-style": "none", // Hides scrollbar in IE/Edge
  
    /* For Webkit-based browsers like Chrome, Safari */
    "&::-webkit-scrollbar": {
      display: "none", // Hides scrollbar
    },
  },

  footerSection: {
    left: "0",
    right: "0",
    bottom: "0",
  },
};

const DashboardLayout = ({
  children,
  sectionName,
  showBackButtonSection,
  title,
  rightComponent,
  showTitleOnly,
}) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const Navigate = useNavigate();

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  return (
    <Box style={dashboardLayoutStyle.mainContainer}>
      <Box style={dashboardLayoutStyle.headerSection}>
        <Header />
      </Box>

      <Box style={dashboardLayoutStyle.contentSection}>
        <Box style={dashboardLayoutStyle.siderbarStyle}>
          <Sidebar onLogoutClick={handleLogoutClick} />
          {isLogoutModalOpen && (
            <LogoutModal onClose={handleCloseLogoutModal} />
          )}
        </Box>
        <Box style={dashboardLayoutStyle.contentStyle}>
          <Box sx={dashboardLayoutStyle.headerContent}>
            <Box style={dashboardLayoutStyle.sectionNameContainer}>
              <Box>
                <Typography style={dashboardLayoutStyle.sectionNameStyle}>
                  {sectionName}
                </Typography>
              </Box>
              <Box>{rightComponent} </Box>
            </Box>

            <Divider />

            {showTitleOnly ? (
              <Typography sx={dashboardLayoutStyle.sectionTitle}>
                {title}
              </Typography>
            ) : (
              showBackButtonSection && (
                <Box sx={dashboardLayoutStyle.titleSection}>
                  <img
                    src={backBtn}
                    alt="backBtn"
                    style={{
                      width: "2.813",
                      height: "2.813",
                      cursor: "pointer",
                    }}
                    onClick={() => Navigate(-1)}
                  />
                  <Typography sx={dashboardLayoutStyle.sectionTitle}>
                    {title}
                  </Typography>
                </Box>
              )
            )}
          </Box>
          <Box sx={dashboardLayoutStyle.scrollableChildren}>{children}</Box>
        </Box>
      </Box>

      <Box style={dashboardLayoutStyle.footerSection}>
        <Footer />
      </Box>
    </Box>
  );
};

DashboardLayout.defaultProps = {
  showBackButtonSection: true,
  showTitleOnly: false,
};

export default DashboardLayout;
