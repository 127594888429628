import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box } from "@mui/material";
import DashboardCard from "../../../components/Cards/DashboardCard";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import FeedbackIcon from '@mui/icons-material/Feedback';
import PendingIcon from '@mui/icons-material/Pending';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { getAPIHandler } from "../../../ApiConfig/service";
import { useNavigate } from "react-router-dom";



const dashboardStyle = {
  dashboardContainer: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  cardSection: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2%",
  },
  cardBox: {
    margin: "10px 0px",
    minWidth: "32%",
  },
};



const Dashboard = () => {
  const [totalArtists, setTotalArtists] = useState(null);
  const [totalListeners, setTotalListeners] = useState(null);
  const [totalFeedBacks, setTotalFeedBacks] = useState(null);
  const [totalPermissions, setTotalPermissions] = useState(null);
  const [totalRespondedFeedBacks, setTotalRespondedFeedBacks] = useState(null);
  const [totalPendingFeedBacks, setTotalPendingFeedBacks] = useState(null);
  const [totalPendingPermissions, setTotalPendingPermissions] = useState(null);
  const [totalgrantedPermissions, setTotalGrantedPermissions] = useState(null);
  const [totaldenyPermissions, setTotalDenyPermissions] = useState(null);

  const navigate= useNavigate();

  const handleClick = () => {
  navigate('/Userlisting')
} 

 const handleFeedBackClick = () => {
  navigate('/FeedbackListing')
 }

 const handlePermissionClick = () => {
  navigate('/permissionListing')
 }

  const fetchArtistData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getAllUserByType",
        paramsData: { userType: "ARTIST" },
      });
  
      if (response && response.data && response.data.result && response.data.result.userData) {
        setTotalArtists(response.data.result.userData.length);
      } else {
        console.error("No artist data received or invalid format. Response:", response);
      }
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  };

  const fetchListenerData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getAllUserByType",
        paramsData: { userType: "USER" },
      });
    
  
      if (response && response.data && response.data.result && response.data.result.userData) {
        setTotalListeners(response.data.result.userData.length);
      } else {
        console.error("No listener data received or invalid format. Response:", response);
      }
    } catch (error) {
      console.error("Error fetching listener data:", error);
    }
  };

   const fetchFeedbackData = async ()  => {
    try {
      const response = await getAPIHandler({
        endPoint : "getAllUserFeedback"
      })

      if (response && response.data && response.data.result && response.data.result.feedbackData) {
        const feedbackData = response.data.result.feedbackData;
        setTotalFeedBacks(response.data.result.feedbackData.length);

        const respondedFeedbacks = feedbackData.filter(feedback => feedback.replySent).length;
        const pendingFeedbacks = feedbackData.length - respondedFeedbacks;

        setTotalRespondedFeedBacks(respondedFeedbacks);
        setTotalPendingFeedBacks(pendingFeedbacks);
      } else {
        console.error("No Feedback data received or invalid format. Response:", response);
      }
    } catch (error) {
      console.error("Error fetching listener data:", error);
    }
   }

   const fetchPermissionData = async () => {
    try {
      const response = await getAPIHandler({ endPoint: "getAllPermissions" });

      console.log()
  
      if (response?.data?.result.permissionData) {
        const permissions = response.data.result.permissionData;

        console.log(permissions, "permissions")

  

        setTotalPermissions(permissions.length);


  

        const grantedPermissions = permissions.filter(
          (item) => item.permission === "GRANTED"
        ).length;
        const pendingPermissions = permissions.filter(
          (item) => item.permission === "PENDING"
        ).length;
        const deniedPermissions = permissions.filter(
          (item) => item.permission === "DENY"
        ).length;
  
        setTotalGrantedPermissions(grantedPermissions);
        setTotalPendingPermissions(pendingPermissions);
        console.log(pendingPermissions, "pendingPermissions")
        setTotalDenyPermissions(deniedPermissions);
      } else {
        console.error(
          "No permission data received. Response structure unexpected:",
          response
        );
      }
    } catch (error) {
      console.error("Error fetching permission data:", error);
    }
  };
  
  useEffect(() => {
    fetchArtistData();
    fetchListenerData();
    fetchFeedbackData();
    fetchPermissionData();
  }, []);

  const cardData = [
    {
      cardIcon: InterpreterModeIcon,
      title: "Total Artists",
      count: totalArtists !== null ? totalArtists : "0",
      onClick: handleClick
    },
    {
      cardIcon: HeadphonesIcon,
      title: "Total Listeners",
      count: totalListeners !== null ? totalListeners : "0",
      onClick: handleClick
    },
    {
      cardIcon: FeedbackIcon,
      title: "Total Feedbacks",
      count: totalFeedBacks !== null ? totalFeedBacks : "0",
      onClick: handleFeedBackClick
    },
    {
      cardIcon: MarkChatReadIcon,
      title: "Resolved Feedbacks",
      count: totalRespondedFeedBacks !== null ? totalRespondedFeedBacks : "0",
      onClick: handleFeedBackClick
    },
    {
      cardIcon: PendingIcon,
      title: "Pending Feedbacks",
      count: totalPendingFeedBacks !== null ? totalPendingFeedBacks : "0",
      onClick: handleFeedBackClick
    },
    {
      cardIcon: PermDataSettingIcon,
      title: "Total Permissions",
      count: totalPermissions !== null ? totalPermissions : "0",
      onClick: handlePermissionClick
    },
    {
      cardIcon: PendingIcon,
      title: "Pending Permissions",
      count: totalPendingPermissions !== null ? totalPendingPermissions : "0",
      onClick: handlePermissionClick
    },
    {
      cardIcon: CheckCircleOutlineIcon,
      title: "Granted Permissions",
      count: totalgrantedPermissions !== null ? totalgrantedPermissions : "0",
      onClick: handlePermissionClick
    },
    {
      cardIcon: DoDisturbAltOutlinedIcon,
      title: "Deny Permissions",
      count: totaldenyPermissions !== null ? totaldenyPermissions : "0",
      onClick: handlePermissionClick
    },
    
  ];

  return (
    <DashboardLayout sectionName="Dashboard" showBackButtonSection={false}>
      <Box sx={dashboardStyle.dashboardContainer}>
        <Box sx={dashboardStyle.cardSection}>
          {cardData.map((card, index) => (
            <Box key={index} sx={dashboardStyle.cardBox}>
              <DashboardCard
                cardIcon={card.cardIcon}
                title={card.title}
                count={card.count} 
                onClick={card.onClick}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
